<template>
  <v-dialog v-model="visible" max-height="500" max-width="700">
    <v-card>
      <v-toolbar dense color="gray" dark>
        <v-toolbar-title class="title" justify="center"
          >Neue Standort/Sozialgefüge</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="form-content">
        <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="5">
              <label for="name:">Abkürzung</label>
              <v-text-field
                class="textFieldOne"
                outlined
                v-model="location.abbreviation"
                :rules="rules.abbreviation"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label for="name:">Beschreibung</label>
              <v-text-field
                class="textFieldTwo"
                outlined
                v-model="location.name"
                :rules="rules.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row>
            <v-btn
              type="submit"
              class="emptyForm"
              style="margin-left:95px; max-width:360px; text-color:black;"
              :loading="isLoading"
              :disabled="disableErstellen || !valid"
              color="primary"
              >Erstellen
            </v-btn>
            <v-btn
              type="button"
              class="emptyForm"
              style="margin-left:25px;"
              :loading="isLoading"
              color="gray"
              :disabled="formSubmitted"
              @click="closeModal"
              >Abbrechen</v-btn
            >
          </v-row>
          <v-alert
            type="success"
            elevation="20"
            v-show="showAlert"
            :color="alertColor"
            class="alert"
          >
            {{ alertText }}
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import UpdateLocation from "./UpdateLocation";

export default {
  formSubmitted: false,
  props: {
    location: {
      type: Object,
      default: () => ({ id: null, abbreviation: "", name: "" })
    },
    visible: Boolean,
    itemList: Array
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      showAlert: false,
      alertText: "",
      alertColor: "",
      rules: {
        abbreviation: [
          v =>
            (v && v.length >= 4) ||
            "Die Abkürzung muss mindestens 4 Zeichen enthalten",
          v =>
            (v && v.length < 5) ||
            "Die Abkürzung darf höchstens 4 Zeichen umfassen"
        ],
        name: [v => !!v || "Deutscher Name ist erforderlich"]
      },
      valid: false
    };
  },
  computed: {
    formTitle() {
      return this.location && this.location.id
        ? "Bewuchs bearbeiten"
        : "Bewuchs Massnahme";
    },
    validForm() {
      return !!this.location.abbreviation && !!this.location.name;
    }
  },
  components: {
    UpdateLocation
  },
  methods: {
    closeModal() {
      this.formSubmitted = false;
      this.$emit("close");
    },
    showUpdateDialog() {
      this.dialog = true;
    },
    submitForm() {
      this.isLoading = true;

      const requestBody = {
        abbreviation: this.location.abbreviation,
        name: this.location.name
      };

      if (!this.location.id) {
        axios
          .post(
            "https://tilia.rrota.org/api/Location/Create",
            requestBody
          )
          .then(response => {
            this.formSubmitted = true;
            this.isLoading = false;
            if (Array.isArray(this.itemList)) {
              this.$set(this.itemList, this.itemList.length, response.data);
            } else {
              this.itemList = [response.data];
            }
            if (response.status === 200) {
              this.showAlert = true;
              this.alertColor = "success";
              this.alertText =
                "Das neue Standort/Sozialgefüge wurde erfolgreich erstellt";
              this.$emit("updated-table", true);
              setTimeout(() => {
                this.closeModal();
                this.showAlert = false;
              }, 2000);
            }
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
            this.showAlert = true;
            this.alertColor = "error";
            this.alertText = "Fehler beim Erstellen der Standort/Sozialgefüge";
            setTimeout(() => {
              this.showAlert = false;
            }, 2000);
          });
      }
    }
  }
};
</script>
<style>
.alert {
  position: fixed !important;
  width: 100% !important;
  max-width: 784px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 40px 100px !important;
}
.form-content {
  margin-top: 20px;
}
</style>
